<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="12">
        <b-card> 
          <div class="table-responsive">
            <b-table striped hover :items="players" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" responsive="sm">
              <template #cell(full_name)="data">
                {{ data.value }}
              </template>
              <template #cell(tournaments_count)="data">
                {{ data.value }}
              </template>
              
            </b-table>
          </div>

        </b-card>
      
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { syncRef } from '@vueuse/core';
import { BRow, BCardHeader, BCardTitle, BCardBody, BCol, BCard, BCardText, BLink, BButton, BImg, BMedia, BMediaAside, BAvatar, BMediaBody, BAlert, BTable  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex';

export default {
  components: { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BLink, BImg, BButton, BMedia, BAvatar, BMediaAside, BMediaBody, BAlert, BTable  },
  directives: {
    Ripple,
  },
  data() {
    return {    
      players: null,
      sortBy: 'tournaments_count', // Valor inicial para sortBy
      sortDesc: true,    // Valor inicial para sortDesc
      fields: [
        { key: 'full_name', label: 'Jugador', sortable: true },
        { key: 'tournaments_count', label: 'Americanas disputadas', sortable: true },        
      ],
    }
  },
  created() {
    this.$http.get("/api/getSumTournaments").then((response) => {
      this.players = response.data;
      this.show = false
    })
  },
  methods: {
   
  
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>

<style></style>
